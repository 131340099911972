.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin avatar($size) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  background-color: #f8f8f8;
  text-align: center;
  border-radius: 50%;

  .initials {
    font-size: $size*0.5;
    line-height: $size*0.5;
    color: #111;
  }
}

.avatar-circle      { @include avatar(100px); }
.avatar-circle-sm   { @include avatar(50px); }
.avatar-circle-xs   { @include avatar(30px); }
.avatar-circle-lg   { @include avatar(250px); }

@media print {
  .w-print-100 {
    width: 100% !important;
  }
}
